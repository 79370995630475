import axios from '@/axios.js'

export default {
  getAllDealers: ({ commit }) => {
    return axios.get('/sales-and-purchase/drcc/customer').then((response) => {
      commit('GET_DEALERS', response.data.data)
    });
  },
  addDealer: ({ commit }, payload) => {
    return axios.post('/sales-and-purchase/drcc/customer', payload);
  },
  deleteDealer: (vuexContext, idDealer) => {
    return axios.delete(`/sales-and-purchase/drcc/customer/${idDealer}`)
  },
  detailDealer({ commit }, idDealer) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/customer/${idDealer}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  updateDealer({ commit }, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      let newPayload = Object.assign({}, { ...payload }, { _id: undefined });

      axios.put(`sales-and-purchase/drcc/customer/${_id}`, newPayload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    })
  },
  updateSalesman({ commit }, payload) {
    let { _id, email } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`sales-and-purchase/drcc/customer/${_id}/salesman`, { email }).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  }
}
