import axios from '@/axios.js'

export default {
  getDelivery({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/delivery').then((response) => {
        commit('SET_DELIVERY', response.data.data)
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },
  getSelfCollectDelivery({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/delivery/self-collect').then((response) => {
        commit('SET_SELF_COLLECT_DELIVERY', response.data.data)
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },
  confirmDelivery({ commit }, idDelivery) {
    return new Promise((resolve, reject) => {
      axios.put('sales-and-purchase/drcc/delivery/confirm', {
        deliverySalesId: idDelivery
      }).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e);
        reject(e);
      })
    });
  },
  createDelivery({ commit }, deliveryData) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/receiving/drcc', deliveryData).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  },
  detailDelivery({ commit }, idDelivery) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/receiving/drcc/${idDelivery}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
      })
    })
  }
}
