import state from './warehouseState'
import mutations from './warehouseMutations'
import actions from './warehouseActions'
import getters from './warehouseGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
