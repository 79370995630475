import axios from '@/axios.js'

export default {
  getWarehouse({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/warehouse/drcc').then((response) => {
        commit('SET_WAREHOUSE', response.data.data);
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  },
  getWarehouseItems({ commit }, idWarehouse) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/warehouse/drcc/items', {
        params: {
          warehouseId: idWarehouse
        }
      }).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        reject(e);
      });
    });
  },
  getDetailListItem({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/warehouse/detail-items-model', {
        params: payload
      }).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  }
}
