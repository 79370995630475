import axios from '@/axios.js';

export default {
  getAllNonOrderDrcc({ commit }, params) {
    return axios.get(`sales-and-purchase/inventory/non-sales`, { params: { ...params } }).then((response) => {
      commit('SET_NON_DRCC_ITEMS', response.data.data);
      return response.data;
    }).catch((e) => {
      console.log(e);
    })
  }
}
