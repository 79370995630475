import axios from '@/axios.js'

export default {
  getSales({ commit }, payload) {
    return axios.get('/sales-and-purchase/sales/drcc', {
      params: payload
    }).then((response) => {
      commit('SET_SALES', response.data.data)
    }).catch((e) => {
      throw e;
    })
  },
  getAllSaleDraft({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/sales/all-draft`).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
      })
    })
  },
  getAllSalesAdmin({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/sales/all').then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    })
  },
  addSale({ commit }, payload) {
    return axios.post('/sales-and-purchase/sales/drcc', payload).then((response) => {
      return response;
    }).catch((e) => {
      throw e;
    })
  },
  saveSaleAsDraft({ commit }, data) {
    axios.post('sales-and-purchase/sales/drcc/draft', data).then((response) => {
      resolve(response.data.data)
    }).catch((e) => {
      console.log(e)
    })
  },
  detailSale(vuexContext, payload) {
    return axios.get('/sales-and-purchase/sales/drcc/detail', {
      params: payload
    });
  },
  testCallbackOrdered({ commit }, payload) {
    return axios.put('/sales-and-purchase/sale/drcc/ordered', payload);
  },
  testOwsCall({ commit }, payload) {
    return axios.post('sales-and-purchase/drcc/test/ows', payload);
  }
}
