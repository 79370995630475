import axios from '@/axios.js'

export default {
  getItems({ commit }) {
    return axios.get('/sales-and-purchase/drcc/items').then((response) => {
      commit('SET_ITEMS', response.data.data)
    }).catch((e) => {
      console.log(e)
    })
  },
  getPublishedItems(context) {
    return axios.get('sales-and-purchase/drcc/published-item').then((response) => {
      return response.data.data
    }).catch((e) => {
      console.log(e)
      return []
    })
  },
  addItem({ commit }, payload) {
    return axios.post('/sales-and-purchase/drcc/item', payload).then((response) => {
    }).catch((e) => {
      console.log(e)
    })
  },
  deleteItem({ commit }, idItemOrModel) {
    return new Promise((resolve, reject) => {
      axios.delete(`sales-and-purchase/drcc/item/${idItemOrModel}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addItemBundle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/item/bundle', payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  listItemBundle({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/item/bundle').then((response) => {
        commit('SET_ITEM_BUNDLE', response.data.data)
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  deleteItemBundle({ commit }, bundleId) {
    return new Promise((resolve, reject) => {
      axios.delete(`sales-and-purchase/drcc/item/bundle/${bundleId}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  updateItemBundle({ commit }, payload) {
    let { id } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`sales-and-purchase/drcc/item/bundle/${id}`, payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  detailBundle({ commit }, bundleId) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/item/bundle/${bundleId}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  }
}
