export default {
  SET_INTERNAL_WARRANTY: (state, warranty) => {
    state.internalWarranty = warranty;
  },
  SET_EXTERNAL_WARRANTY: (state, warranty) => {
    state.externalWarranty = warranty;
  },
  SET_EMAIL: (state, payload) => {
    state.email = payload;
  },
  SET_WARRANTY: (state, warranty) => {
    state.warranty = warranty;
  }
}
