import axios from '@/axios.js';

export default {
  getInternalWarranty({ commit }, params) {
    return axios.get('sales-and-purchase/drcc/submitted-warranty/list', {
      params: {
        type: 'Internal',
        ...params,
      }
    }).then((response) => {
      commit('SET_INTERNAL_WARRANTY', response.data.data)
      return response.data.data;
    }).catch((e) => {
      console.log(e)
    })
  },
  getExternalWarranty({ commit }, params) {
    return axios.get('sales-and-purchase/drcc/submitted-warranty/list', {
      params: {
        type: 'External',
        ...params,
      }
    }).then((response) => {
      commit('SET_EXTERNAL_WARRANTY', response.data.data)
      return response.data.data;
    }).catch((e) => {
      console.log(e)
    })
  },
  setEmail({commit},payload) {
    commit('SET_EMAIL',payload)
  },
  getIndividualWarranty({ commit }, params) {
    return axios.post('sales-and-purchase/drcc/warranty-individual',params).then((response) => {
      // commit('SET_EXTERNAL_WARRANTY', response.data.data)
      return response.data.data;
    }).catch((e) => {
      console.log(e)
    })
  }, 
}
