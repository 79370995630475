export default {
  warehouseNormalize: function (state) {
    return state.warehouse.map((w) => {
      let { name, location, id } = w;
      return Object.assign({}, { id, name, location: location.name })
    });
  },
  warehouseOptions: function (state) {
    return state.warehouse.map((w) => {
      let { name, id } = w;
      return Object.assign({}, { value: id, text: name })
    })
  }
}
