export default {
  REMOVE_DEALER: (state, payload) => {
    state.dealers = state.dealers.filter(d => {
      return d._id === payload
    })
  },
  GET_DEALERS: (state, payload) => {
    state.dealers = payload
  }
}
