import axios from '@/axios'

export default {
  register: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/register', Object.assign(payload, { app: 'DRCC' }))
        .then(res => {
          commit('AUTH_USER', res.data.token)
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  },

  login: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login', payload)
        .then(res => {
          commit('AUTH_USER', res.data.token)
          // update axios authentication token
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  fetchUser: ({ commit, state }) => {
    if (state.token === '') {
      return
    }
    new Promise((resolve, reject) => {
      axios
        .get('/auth/current-user')
        .then(res => {
          commit('USER_DETAIL', res.data.currentUser)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  getUser: ({ commit, state }, userId) => {
    return axios.get(`auth/users/${userId}`);
  },

  newUser: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('auth/users', Object.assign(payload, { app: 'DRCC' })).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    });
  },
  newUserRandomPW: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('auth/new-user', Object.assign(payload, { app: 'DRCC' })).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    });
  },

  getUsersBy: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.get('/auth/users/by', {
        params: Object.assign(payload, { app: 'DRCC' })
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },

  deleteUser: (context, idUser) => {
    return new Promise((resolve, reject) => {
      axios.delete(`/auth/users/${idUser}`).then((response) => {
        resolve();
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    })
  }
}
