import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

// Auth
import moduleAuth from './auth/moduleAuth'

// Dealer
import moduleDealer from './dealer/moduleDealer'

// Item
import moduleItem from './item/moduleItem'

// Sale
import moduleSale from './sales/moduleSale'

// Delivery
import moduleDelivery from './delivery/moduleDelivery'

// Warehouse
import moduleWarehouse from './warehouse/moduleWarehouse'

// Installer
import moduleInstaller from './installer/moduleInstaller';

import moduleInventory from './inventory/moduleInventory';

import moduleWarranty from './warranty/moduleWarranty';

import moduleMapAPI from "./mapAPI/moduleMapAPI";

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    verticalMenu,

    // Auth
    auth: moduleAuth,

    // Dealer
    dealer: moduleDealer,

    // Item
    item: moduleItem,

    // Sale
    sale: moduleSale,

    // Delivery
    delivery: moduleDelivery,

    // Warehouse
    warehouse: moduleWarehouse,

    // Installer
    installer: moduleInstaller,

    inventory: moduleInventory,

    warranty: moduleWarranty,

    //for address autofill
    mapAPI : moduleMapAPI
  },
  strict: process.env.DEV,
})
