import state from './installerState'
import mutations from './installerMutations'
import actions from './installerActions'
import getters from './installerGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
