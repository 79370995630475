import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

// import adminRoute from './admin-route';
// import dealerRoute from './dealer-route';
// import installerRoute from './installer-route';
// import sumitomoRoute from './sumitomo-route';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/detail-warranty/:id',
      name: 'detail-warranty',
      component: () => import('@/views/customer/detail-warranty-individual.vue'),
      props:true,
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/list-warranty',
      name: 'list-warranty',
      component: () => import('@/views/customer/list-warranty-individual.vue'),
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/qrcode-test',
      name: 'qrcode-test',
      component: () => import('@/views/QrcodeTest.vue'),
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/zxing-test',
      name: 'zxing-test',
      component: () => import('@/views/ZxingTest.vue'),
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/zbar-test',
      name: 'zbar-test',
      component: () => import('@/views/ZbarTest.vue'),
      meta: {
        layout: 'full'
      },
    },
    {
      path: '/registration-success',
      name: 'register-warranty-success',
      component: () => import('@/views/Thankyou.vue'),
      meta: {
        layout: 'center'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/login',
      name: 'page-login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/register',
      name: 'page-register',
      component: () => import('@/views/register/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/verified',
      name: 'page-verified',
      component: () => import('@/views/verification/SuccessVerification.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/change-password',
      name: 'page-change-password',
      component: () => import('@/views/verification/ChangePassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/confirm-user/:confirmToken',
      name: 'page-user-confirm-email',
      component: () => import('@/views/verification/ConfirmUser.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/workorder',
      name: 'workorder-scan',
      component: () => import('@/views/Warranty.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '',
      component: () => import('../layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/',
          name: 'external-warranty-reg',
          component: () => import('@/views/ExternalWarranty.vue'),
          meta: {
            layout: 'full'
          },
        },
        {
          path: '/viewwarranty',
          name: 'external-warranty-login',
          component: () => import('@/views/loginOtp.vue'),
          meta: {
            layout: 'full'
          },
        },
        
      ],
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/getToken'] === '') {
      store.commit('auth/SET_PREV_AUTH_PAGE', to.path);

      next({
        path: '/pages/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
