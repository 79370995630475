export default {
  AUTH_USER: (state, payload) => {
    state.token = payload
  },

  USER_DETAIL: (state, payload) => {
    state.activeUser = payload
  },

  SET_PREV_AUTH_PAGE: (state, payload) => {
    state.prevAuthPage = payload;
  }
}
