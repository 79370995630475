export default {
  NON_DRCC_ITEMS_CUSTOMER: (state) => {
    return state.nonDrccItem.map((item) => {
      let customer = item.customer;

      if (customer === undefined) {
        return Object.assign(item, { customer: {type: 'Individual', nameFirst: '', nameLast: ''}});
      }

      return item;
    });
  }
}
