import axios from '@/axios.js'

export default {
  getInstaller({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/installer').then((response) => {
        commit('SET_INSTALLER', response.data.data);
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      })
    })
  },
  addInstaller({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/installer', payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        reject(e)
      })
    });
  },
  updateInstaller({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { _id } = payload;
      let newData = Object.assign({}, payload, { _id: undefined });
      axios.put(`sales-and-purchase/drcc/installer/${_id}`, newData).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  },
  deleteInstaller({ commit }, idInstaller) {
    return new Promise((resolve, reject) => {
      axios.delete(`sales-and-purchase/drcc/installer/${idInstaller}`).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e);
        reject(e);
      });
    });
  },
  detailInstaller({ commit }, idInstaller) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/installer/${idInstaller}`).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e);
        reject(e);
      })
    })
  }
}
